* {
  margin: 0;
  padding: 0;
}
.logo {
  transform: translateY(-35%);
}
.section1 {
  background-image: url(./assets/bg.png);
  background-size: cover;
  background-position: center;
  height: 100%;
  text-align: center;
  font-family: "Luckiest Guy", cursive;
}

.main {
  padding: 200px 0px;
  color: #fff;
}
.batjockBlock{
  display: none;
  text-align: center;
}

.heading {
  padding-top: 20px;
  font-size: 154px;
  -webkit-text-stroke: 1px black;
}

.socialsBlock {
  margin-top:10px;
  transform: translateY(-50%);
  animation: floatAnimation 2s ease-in-out infinite; /* Adjust the animation duration and timing function as needed */
}
.button {
  margin-top: 50px;
  padding: 30px 20px 15px 20px;
  border: 4px solid black;
  border-radius: 10px;
  background-color: #4FD2EF;
  font-size: 52px;
  font-family: "Luckiest Guy", cursive;
  color:#fff;
  box-shadow: 0px 10px 20px #1989A2;
  -webkit-text-stroke: 2px black;
}

.section2{
  background-image: url("./assets/bg2.png");
  background-size: cover;
  background-position: center;
  text-align: center;
}
.heading2 {
  padding-top: 100px;
  font-size: 80px;
  font-family: "Luckiest Guy", cursive;
  color: #fff;
  -webkit-text-stroke: 1px black;
}
.heading3 {
  padding-top:10px;
  font-size: 80px;
  font-family: "Luckiest Guy", cursive;
  color: #fff;
  -webkit-text-stroke: 1px black;
}
.desc {
  margin-top: 50px;
  padding-bottom: 100px;
}

.section3{
  background-image: url("./assets/bg3.png");
  background-size: cover;
  background-position: center;
  height:680px;
}
.divider {
  height:480px;
}

.section4{
  background-color: #303030;
  text-align: center;
}

.footer {
  padding: 10px;
  
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust the floating distance as needed */
  }
}
.ca {
  padding:10px 20px;
  margin: 0 auto;
  background-color: #4FD2EF;
  width: 800px;
  border-radius: 10px;
  border: 4px solid black;
  margin-bottom: 30px;
}
.caText {
  font-family: "Luckiest Guy", cursive;
  color: #000;
  font-size: 30px;
}
.social {
  margin: 0px 20px;
}
@media only screen and (max-width: 500px) {
  .logo{
    max-width: 300px;
  }
  .main{
    padding: 100px 0px;
  }
  .heading{
    font-size: 80px;
  }
  .heading2,.heading3{
    font-size: 40px;
  }
  .batjockBlock{
    display: block;
    text-align: center;
  }
  .section3{
    background-image: url("./assets/bgmob.png");
  }
  .ca {
    width: 420px;
  }
  .caText{
    font-size:14px;
    font-family: "Verdana", sans-serif;
  }
}
@media only screen and (min-width: 1600px) {
  .main{
    padding-bottom: 480px;
  }
}
@media only screen and (min-width: 1400px) {
  .main{
    padding-bottom: 680px;
  }
}
